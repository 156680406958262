<template>
  <div>
    <v-combobox
      @input="onSelect"
      :value="value"
      :items="procedures"
      :loading="loading"
      :search-input.sync="search"
      item-text="name"
      item-value="id"
      return-object
      placeholder="Busque aqui"
      :label="label"
      class="required"
      hide-details
      :clearable="!disabled && !readonly"
      :disabled="disabled"
      :readonly="readonly"
      chips
      :multiple="multiple"
      :showPrices="showPrices"
      ref="procedureAutocomplete"
    >
      <template v-slot:no-data>
        <div class="pa-3">Nenhum procedimento encontrado para a busca</div>
      </template>

      <template v-slot:selection="data">
        <v-chip v-if="chips" color="blue lighten-4 pa-6"
          >{{ data.item.name }}
          <v-chip v-if="showPrices" color="white ml-2">
            {{
              formatMoney(
                data.item.ProcedureCpBranches[0].BranchProcedurePricings[0]
                  .price
              )
            }}</v-chip
          >
        </v-chip>

        <div v-else>
          {{ data.item.name }}
          <span v-if="showPrices" color="white ml-2">
            {{
              formatMoney(
                data.item.ProcedureCpBranches[0].BranchProcedurePricings[0]
                  .price
              )
            }}
          </span>
        </div>
      </template>

      <template v-slot:item="data">
        {{ data.item.name }}
        <v-chip v-if="showPrices" color="blue lighten-4 ml-2">
          {{
            formatMoney(
              data.item.ProcedureCpBranches[0].BranchProcedurePricings[0].price
            )
          }}</v-chip
        >
      </template>

      <template v-if="allowCreate" v-slot:append-item>
        <v-btn text small @click.stop="insertionDialog = true">
          Cadastrar procedimento
          <v-icon right color="blue">mdi-plus-circle-outline</v-icon></v-btn
        >
      </template>
    </v-combobox>

    <v-dialog v-model="insertionDialog">
      <ProcedureForm
        :mod="'insert'"
        :modalTitle="'Cadastrar procedimento'"
        :type="type"
        dialog
        divider
        @close-dialog="insertionDialog = false"
        @insert-completed="setProcedureAdded"
      />
    </v-dialog>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import ProcedureForm from "@/views/Procedures/ProcedureForm";

export default {
  components: { ProcedureForm },
  props: [
    "value",
    "label",
    "multiple",
    "type",
    "examMode",
    "examSubspecialty",
    "disabled",
    "readonly",
    "companyBranch",
    "showPrices",
    "allowCreate",
    "chips",
  ],
  data() {
    return {
      insertionDialog: false,
      loading: false,
      search: null,
      procedures: [],
    };
  },
  watch: {
    search(val) {
      if (this.loading || !val || val !== this.search) return;

      this.loadProcedures(val);
    },
    type() {
      this.clearAutocomplete();
    },
    examMode() {
      this.clearAutocomplete();
    },
    examSubspecialty() {
      this.clearAutocomplete();
    },
  },
  methods: {
    formatMoney(money) {
      // console.log(money);
      return parseFloat(money).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },
    onSelect(evt) {
      if (evt) {
        // console.log(evt);
        let filteredData;

        if (this.multiple)
          filteredData = this.showPrices
            ? evt.filter((e) => e.name && e.id && e.ProcedureCpBranches.length)
            : evt.filter((e) => e.name && e.id);
        else {
          // console.log("CAIU NO ELSE")
          if (evt.name && evt.id) filteredData = evt;
        }

        this.$emit("input", filteredData);
        this.search = null;
      }
    },
    setProcedureAdded(data) {
      this.procedures.push(data);

      this.$emit("input", data);

      this.insertionDialog = false;
    },
    setProcedures(data) {
      const copyOfData = [...data];

      if (!copyOfData || !copyOfData.length) return;

      this.procedures = this.showPrices
        ? copyOfData.filter(
            (p) =>
              p.ProcedureCpBranches &&
              p.ProcedureCpBranches.length &&
              p.ProcedureCpBranches[0].BranchProcedurePricings &&
              p.ProcedureCpBranches[0].BranchProcedurePricings.length
          )
        : copyOfData;
    },
    async loadProcedures(search) {
      try {
        this.loading = true;

        const params = {
          search,
          pagination: 0,
          type: this.type,
        };

        if (this.type === "exam") {
          params.examMode = this.examMode;
          params.examSubspecialty = this.examSubspecialty;
        }

        if (this.companyBranch) params.companyBranch = this.companyBranch;

        const response = await this.$axios.get(`/procedures`, { params });

        this.setProcedures(response.data);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      } finally {
        this.loading = false;
      }
    },
    clearAutocomplete() {
      this.search = null;
      this.$emit("input", null);
      this.$refs.procedureAutocomplete.cachedItems = [];
    },
  },
  computed: {},
};
</script>

<style>
</style>