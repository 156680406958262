<template>
  <BaseForm
    :title="title"
    :mod="mod"
    :flat="flat"
    :divider="divider"
    :dialog="dialog"
    :loading="loading"
  >
    <template #content>
      <v-container fluid v-if="!loading">
        <v-row>
          <v-col cols="12" md="8">
            <v-row>
              <v-col>
                <h2>
                  {{ selectedExamMode.text }}
                </h2>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <div class="show-archives">
                  <v-list-item
                    v-bind:key="item.id"
                    v-for="item in examData.ExamFiles"
                  >
                    <v-list-item-icon>
                      <v-icon> mdi-image </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-btn
                          text
                          @click="setImageShow(item.id, item.fileName)"
                          >{{ item.fileName }}
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <div
                  class="d-flex justify-center align-center pa-3"
                  v-if="loadingFileUrl"
                >
                  <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </div>

                <div
                  v-else
                  class="d-flex flex-column justify-space-between align-center"
                >
                  <v-alert
                    v-if="!srcShowImage"
                    border="right"
                    color="blue-grey"
                    dark
                  >
                    Clique em um arquivo acima para visualizar (Somente para
                    imagens)
                  </v-alert>

                  <v-slider
                    v-if="srcShowImage"
                    v-model="width"
                    class="align-self-stretch"
                    min="200"
                    max="500"
                    step="1"
                  ></v-slider>

                  <v-img
                    v-if="srcShowImage"
                    :aspect-ratio="1"
                    :width="width"
                    :src="srcShowImage"
                  ></v-img>
                </div>
              </v-col>
            </v-row>

            <v-row class="mb-2">
              <v-col>
                <v-btn small text @click="downloadFiles">
                  <v-icon left> mdi-download</v-icon>
                  Baixar arquivos como zip
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="4">
            <v-row>
              <v-col>
                <h2>Dados do paciente</h2>
              </v-col>
            </v-row>

            <v-row>
              <v-col>Nome: {{ examData.Patient.Person.fullName }}</v-col></v-row
            >
            <v-row>
              <v-col>Sexo: {{ patientGender }}</v-col></v-row
            >
            <v-row>
              <v-col
                >Data de Nascimento: {{ patientDateOfBirth }} -
                {{ patientAge }} anos</v-col
              ></v-row
            >
            <v-row
              ><v-col
                >Profissão: {{ examData.Patient.Person.occupation }}</v-col
              ></v-row
            >
            <v-row>
              <v-col
                >Empresa: {{ examData.Patient.Person.company }}</v-col
              ></v-row
            >
            <v-row
              ><v-col
                >CPF: {{ examData.Patient.Person.taxDocument }}</v-col
              ></v-row
            >
            <v-row
              ><v-col>RG: {{ examData.Patient.Person.rg }}</v-col></v-row
            >
            <v-row
              ><v-col>Urgente: {{ examUrgency }}</v-col></v-row
            >
            <v-row
              ><v-col>Data do Exame: {{ examDate }}</v-col></v-row
            >
            <v-row>
              <v-col>Dados clinícos: {{ examData.clinicalData }}</v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="mt-5">
          <v-col><h3>Dados clínicos</h3></v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-textarea
              solo
              rows="3"
              clear-icon="mdi-close-circle"
              hide-details
              v-model="formClinicalData"
              :disabled="examData.examStatus !== 'pending'"
              counter="255"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row class="mt-5">
          <v-col><h3>Exame</h3></v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12">
            <ProceduresAutocomplete
              :companyBranch="companyBranchId"
              type="exam"
              :multiple="false"
              v-model="selectedProcedure"
              :showPrices="false"
              :allowCreate="false"
            />
          </v-col>
        </v-row>

        <v-row class="mt-5" v-if="examData.examStatus === 'pending'">
          <v-col><h3>Máscaras de laudo</h3></v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col>
            <ExamModeSelect v-model="selectedExamMode" :allowCreate="false" />
          </v-col>
        </v-row>

        <v-row no-gutters v-if="examData.examStatus === 'pending'">
          <v-col cols="12" class="masks">
            <div
              v-if="loadingMasks"
              class="d-flex justify-center align-center py-2"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>

            <div v-else class="py-2 d-flex flex-wrap">
              <v-checkbox
                :key="mask.id"
                v-for="mask in masks"
                hide-details
                v-model="selectedMasks"
                :value="mask.id"
                :label="mask.name"
                class="mask"
                @change="addOrRemoveMask(mask, $event)"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-5">
          <v-col>
            <h3>Laudo</h3>
          </v-col>
        </v-row>

        <v-row class="mt-2 mb-2">
          <v-col>
            <v-card class="pa-2">
              <ckeditor
                v-if="examData.examStatus === 'pending'"
                v-model="formReport"
                :config="editorConfig"
                @namespaceloaded="onNamespaceLoaded"
              ></ckeditor>
              <div class="pa-3" v-else v-html="formReport"></div>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col><h3>Observações</h3></v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-textarea
              solo
              rows="3"
              clear-icon="mdi-close-circle"
              v-model="formObservations"
              :disabled="examData.examStatus !== 'pending'"
              hide-details
              counter="255"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>

      <v-dialog v-model="successDialog" persistent max-width="450">
        <v-card>
          <v-card-text>
            <div class="text-center pa-4">
              <v-icon large color="success">mdi-check</v-icon>
            </div>

            <div class="text-center pa-3">
              <h3>Dados atualizados com sucesso</h3>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeSuccessDialog">Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template #actions v-if="!loading">
      <v-spacer></v-spacer>
      <div v-if="examData.examStatus === 'pending'">
        <v-btn small @click="$router.go(-1)">
          <v-icon left> mdi-close</v-icon>
          Cancelar
        </v-btn>
        <v-btn color="warning" class="ml-2" @click="requestResend" small>
          <v-icon left> mdi-refresh </v-icon>
          Solicitar reenvio
        </v-btn>
        <v-btn small color="primary" class="ml-2" @click="save('draft')">
          <v-icon left> mdi-content-save-alert </v-icon>
          Salvar rascunho
        </v-btn>
        <v-btn small color="success" class="ml-2" @click="save('published')">
          <v-icon left> mdi-draw</v-icon>
          Salvar e assinar
        </v-btn>
      </div>
    </template>
  </BaseForm>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
import errorHandler from "@/helpers/error_handler";
import BaseForm from "@/components/template/BaseForm";
import formMixin from "@/mixins/formMixin";
import CKEditor from "ckeditor4-vue";
import { baseUrl } from "@/config/config.json";
import ProceduresAutocomplete from "@/components/template/FormElements/Procedures/ProceduresAutocomplete.vue";
import ExamModeSelect from "@/components/template/FormElements/Masks/ExamModeSelect";

export default {
  components: {
    BaseForm,
    ExamModeSelect,
    ProceduresAutocomplete,
    ckeditor: CKEditor.component,
  },
  mixins: [formMixin],
  data() {
    return {
      editorData: "",
      srcShowImage: null,
      loadingMasks: false,
      editorConfig: {
        extraAllowedContent: "div(*)[*]",
        disableNativeSpellChecker: false,
        removeButtons:
          "ImageButton,HiddenField,Flash,Smiley,Iframe,About,Source,Anchor",
        extraPlugins: ["justify", "font", "lineheight"],
      },
      loading: false,
      loadingFileUrl: false,
      successDialog: false,
      width: 300,
      selectedMasks: [],
      imageExtensions: [
        "ase",
        "art",
        "bmp",
        "blp",
        "cd5",
        "cit",
        "cpt",
        "cr2",
        "cut",
        "dds",
        "dib",
        "djvu",
        "egt",
        "exif",
        "gif",
        "gpl",
        "grf",
        "icns",
        "ico",
        "iff",
        "jng",
        "jpeg",
        "jpg",
        "jfif",
        "jp2",
        "jps",
        "lbm",
        "max",
        "miff",
        "mng",
        "msp",
        "nitf",
        "ota",
        "pbm",
        "pc1",
        "pc2",
        "pc3",
        "pcf",
        "pcx",
        "pdn",
        "pgm",
        "PI1",
        "PI2",
        "PI3",
        "pict",
        "pct",
        "pnm",
        "pns",
        "ppm",
        "psb",
        "psd",
        "pdd",
        "psp",
        "px",
        "pxm",
        "pxr",
        "qfx",
        "raw",
        "rle",
        "sct",
        "sgi",
        "rgb",
        "int",
        "bw",
        "tga",
        "tiff",
        "tif",
        "vtf",
        "xbm",
        "xcf",
        "xpm",
        "3dv",
        "amf",
        "ai",
        "awg",
        "cgm",
        "cdr",
        "cmx",
        "dxf",
        "e2d",
        "egt",
        "eps",
        "fs",
        "gbr",
        "odg",
        "svg",
        "stl",
        "vrml",
        "x3d",
        "sxd",
        "v2d",
        "vnd",
        "wmf",
        "emf",
        "art",
        "xar",
        "png",
        "webp",
        "jxr",
        "hdp",
        "wdp",
        "cur",
        "ecw",
        "iff",
        "lbm",
        "liff",
        "nrrd",
        "pam",
        "pcx",
        "pgf",
        "sgi",
        "rgb",
        "rgba",
        "bw",
        "int",
        "inta",
        "sid",
        "ras",
        "sun",
        "tga",
      ],
    };
  },
  watch: {
    selectedProcedure(newProcedure) {
      if (newProcedure) {
        const examMode = newProcedure.ExamReference.ExamMode;

        this.setVSelectedExamMode(examMode.id);
      }
    },
    selectedExamMode(newExamMode) {
      console.log(newExamMode);
      if (newExamMode) this.loadMasks(newExamMode);
    },
  },
  computed: {
    ...mapState("medicalReport", [
      "form",
      "masks",
      "mode",
      "examData",
      "vSelectedProcedure",
      "vSelectedExamMode",
    ]),
    ...mapGetters("medicalReport", ["getFormData"]),
    selectedProcedure: {
      get: function () {
        return this.vSelectedProcedure;
      },
      set: function (value) {
        this.setVSelectedProcedure(value);
      },
    },
    selectedExamMode: {
      get: function () {
        return this.vSelectedExamMode;
      },
      set: function (value) {
        this.setVSelectedExamMode(value);
      },
    },
    formClinicalData: {
      get: function () {
        return this.getFormData("clinicalData");
      },
      set: function (value) {
        this.setFormData({ key: "clinicalData", value: value });
      },
    },
    formReport: {
      get: function () {
        return this.getFormData("report");
      },
      set: function (value) {
        this.setFormData({ key: "report", value: value });
      },
    },
    formObservations: {
      get: function () {
        return this.getFormData("observations");
      },
      set: function (value) {
        this.setFormData({ key: "observations", value: value });
      },
    },
    companyBranchId() {
      return this.examData.type === "private"
        ? this.examData.SchedulingProcedure.Scheduling.CompanyBranchId
        : null;
    },
    examReferenceId() {
      return this.examData.type === "public"
        ? this.examData.ExamReferenceId
        : this.examData.SchedulingProcedure.Procedure.ExamReference.id;
    },
    patientGender() {
      return this.examData.Patient.Person.gender === "male"
        ? "Masculino"
        : "Feminino";
    },
    patientAge() {
      return Math.floor(
        this.$moment().diff(
          this.examData.Patient.Person.dateOfBirth,
          "years",
          true
        )
      );
    },
    patientDateOfBirth() {
      return this.$moment(this.examData.Patient.Person.dateOfBirth).format(
        "DD/MM/YYYY"
      );
    },
    examDate() {
      return this.$moment(this.examData.dateOfRealization).format(
        "DD/MM/YYYY [ás] HH:mm"
      );
    },
    examUrgency() {
      return this.examData.urgent ? "Sim" : "Não";
    },
    examTitle() {
      return "titulo";
    },
  },
  methods: {
    ...mapActions("medicalReport", [
      "vInitForm",
      "vLoadMasks",
      "vLoadExamData",
      "vInsert",
      "vUpdate",
      "vRequestResend",
      "vLoadMedicalReportData",
      "vGetExamFileUrl",
    ]),
    ...mapMutations("medicalReport", [
      "setFormData",
      "initForm",
      "clearForm",
      "setMode",
      "setVSelectedProcedure",
      "setVSelectedExamMode",
      "clearState",
    ]),
    downloadFiles() {
      const examId = this.$route.params.examId;
      window.open(`${baseUrl}/exams/${examId}/download`, "_blank");
    },
    async setImageShow(fileId, fileName) {
      try {
        const extension = fileName.split(".").pop();

        this.loadingFileUrl = true;

        const examId = this.$route.params.examId;

        const fileUrl = `${baseUrl}/exams/${examId}/exam-files/${fileId}`;

        if (this.imageExtensions.includes(extension)) {
          this.srcShowImage = fileUrl;
        } else {
          window.open(fileUrl, "_blank");
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loadingFileUrl = false;
      }
    },
    async save(reportStatus) {
      const errors = this.validateForm();

      if (errors.length) {
        this.$root.$errorDialog(errors, {
          width: "800",
          color: "primary",
        });
      } else {
        if (
          !(await this.$root.$confirm(
            "Atenção",
            "Deseja confirmar as alterações no laudo?",
            {
              color: "primary",
            }
          ))
        )
          return;

        await this.saveMedicalReport(reportStatus);
      }
    },
    async saveMedicalReport(reportStatus) {
      try {
        this.loading = true;

        const examId = this.$route.params.examId;

        await this.vUpdate({ examId, status: reportStatus });

        this.successDialog = true;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
    async requestResend() {
      try {
        if (
          !(await this.$root.$confirm(
            "Atenção",
            "Deseja realmente solicitar o reenvio do exame?",
            {
              color: "primary",
            }
          ))
        )
          return;

        this.loading = true;

        const examId = this.$route.params.examId;

        await this.vRequestResend({ examId });

        this.successDialog = true;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
    validateForm() {
      const errors = [];

      if (!this.formReport || !this.formReport.length)
        errors.push("Preencha o conteúdo do laudo");

      if (!this.formClinicalData || !this.formClinicalData.length)
        errors.push("Informe os dados clínicos");

      return errors;
    },
    removeMask(maskId) {
      const reportContent = this.stringToHTML(this.formReport);

      const masks = reportContent.getElementsByClassName("mask-content");

      for (let i = 0; i < masks.length; i++) {
        if (masks[i].getAttribute("data-id") === `mask-${maskId}`) {
          masks[i].remove();
        }
      }

      this.formReport = reportContent.outerHTML;
    },
    addMask(maskData) {
      this.formReport += `<div data-id="mask-${maskData.id}" class="mask-content">${maskData.mask}</div>`;
    },
    addOrRemoveMask(maskData, selecteds) {
      const copyOfMaskData = { ...maskData };

      if (selecteds.findIndex((i) => i === maskData.id) !== -1) {
        this.addMask(copyOfMaskData);
      } else this.removeMask(copyOfMaskData.id);
    },
    supportDOMParser() {
      if (!window.DOMParser) return false;
      var parser = new DOMParser();
      try {
        parser.parseFromString("x", "text/html");
      } catch (err) {
        return false;
      }
      return true;
    },
    stringToHTML(str) {
      if (this.supportDOMParser()) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(str, "text/html");
        return doc.body;
      }

      const dom = document.createElement("div");
      dom.innerHTML = str;
      return dom;
    },
    async loadMedicalReportData() {
      try {
        const examId = this.$route.params.examId;

        this.loading = true;

        this.vLoadMedicalReportData({ examId });
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
    async loadMasks(examModeId) {
      try {
        this.loadingMasks = true;

        await this.vLoadMasks({
          params: { examMode: examModeId },
        });
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loadingMasks = false;
      }
    },
    async init() {
      try {
        this.loading = true;

        const examId = this.$route.params.examId;

        if (this.mod === "update")
          await this.vLoadMedicalReportData({ examId });
        else if (this.mod === "insert") await this.vLoadExamData({ examId });
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
    async downloadPDF() {
      try {
        this.loading = true;

        const examId = this.$route.params.examId;

        await this.$axios.get(`/exams/${examId}/medical-report/pdf`);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
    handleError(error) {
      console.log(error);

      const errorHandled = errorHandler.treatError(error);

      this.$root.$errorDialog(errorHandled);
    },
    closeSuccessDialog() {
      this.successDialog = false;
      this.$router.go(-1);
    },
    onNamespaceLoaded(CKEDITOR) {
      CKEDITOR.config.enterMode = CKEDITOR.ENTER_BR;
      CKEDITOR.plugins.addExternal(
        "lineheight",
        "https://multimagemra-static.nyc3.digitaloceanspaces.com/ckeditor/plugins/lineheight/plugin.js",
        ""
      );
    },
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    this.selectedMasks = [];
    this.clearForm();
    this.clearState();
  },
};
</script>

<style scoped>
.masks {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.mask {
  display: inline;
  margin-left: 20px;
}

.show-archives {
  height: 300px;
  overflow-y: scroll;
}
</style>
